import { useEffect, useRef } from "react";
import { ProgressBar } from "react-bootstrap";

import greenCircle from "../assets/greenCircle.png";
import redCircle from "../assets/redCircle.png";
import { PieChart } from "@mui/x-charts";
import styles from "./styles/statusGroup.module.css";


const StatusGroup = (props) => {
  /**************************** States ****************************/
  const timerId = useRef();
  const screenWidth = window.innerWidth;

  /**************************** Functions ****************************/
  useEffect(() => {
    timerId.current = setInterval(() => {
      props.setCurrentTimerValue((prev) => prev - 0.5); // set timer from current second to previous
    }, 1000); // 1000 means 1 second
    return () => clearInterval(timerId.current); // clean up timer after each second
  }, [props]);

  useEffect(() => {
    if (props.timerValue <= 0 && props.timed === true) {
      clearInterval(timerId.current);
    }
  }, [props.timerValue, props.timed]);

  const formatTime = (time) => {
    let minutes = Math.floor(time / 60);
    let seconds = Math.floor(time % 60);
    if (seconds < 10) seconds = "0" + seconds;
    if (minutes < 10) minutes = "0" + minutes;
    return <p>{minutes + ":" + seconds}</p>;
  };

  /**************************** Render ****************************/
  return (
    <div className={styles.statusGroup}>
      {/**************************** timerGroup ****************************/}
      <div style={{ display: props.timed ? 'flex' : 'none' }} className={styles.timerGroup}>
        {props.timed ? (
          <PieChart
            colors={["green", "grey"]}
            series={[
              {
                data: [
                  { id: 0, value: props.timerValue },
                  { id: 1, value: props.maxTime * 60 - props.timerValue },
                ],
              },
            ]}
            style={styles.chart}
            width={screenWidth <= 900 ? 50 : 100}
            height={screenWidth <= 900 ? 50 : 100}
            margin={{ right: 0 }}
          />
        ) : (
          <></>
        )}
        <div className={`${styles.timerText} boldText`}>
          {props.timed ? formatTime(props.timerValue) : <div></div>}
        </div>
      </div>

      <div className={styles.rightGroup}>
        <ProgressBar
          className={styles.ProgressBar}
          style={{ height: screenWidth <= 750 ? "25px" : "40px" }}
          variant="success"
          now={props.currentQuestion}
          max={props.max}
          min={0}
        />

        <div className={styles.bottomGroup}>
          <div className={styles.amount}>
            <img className={styles.image} alt="" src={redCircle} />
            <div className={`boldText`}>{props.incorrectAmount}</div>
          </div>
          <div className={styles.amount}>
            <img className={styles.image} alt="" src={greenCircle} />
            <div className={`boldText`}>{props.correctAmount}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StatusGroup;
