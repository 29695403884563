import { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { Spinner } from "react-activity";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import {
  trafficRuleQuestions,
  trafficSignQuestions,
} from "../components/questions.js";
import StatusGroup from "../components/StatusGroup.js";
import styles from "./styles/quiz.module.css";

const Quiz = () => {
  /**************************** States ****************************/
  const navigate = useNavigate();
  const location = useLocation();
  const {
    numOfTrafficSignQuestions,
    numOfTrafficRuleQuestions,
    timed,
    timerValue,
    questionsList,
  } = location.state;

  const [maxTimerValue] = useState(timerValue);
  const [currentTimerValue, setCurrentTimerValue] = useState(timerValue);

  const [currentQuestion, setCurrentQuestion] = useState({
    Question: null,
    Image: null,
    OptionA: null,
    OptionB: null,
    OptionC: null,
    OptionD: null,
    Correct: null,
    Info: null,
  });
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [numOfSignQuestionsCorrect, setNumOfSignQuestionsCorrect] = useState(0);
  const [numOfRuleQuestionsCorrect, setNumOfRuleQuestionsCorrect] = useState(0);
  const [currentSection, setCurrentSection] = useState("");
  const [optionSelected, setOptionSelected] = useState("None");
  const [showHint, setShowHint] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  /**************************** Functions ****************************/
  /******* getCurrentQuestion *******/
  const getCurrentQuestion = async () => {
    let question;
    if (numOfTrafficSignQuestions > currentQuestionIndex) {
      setCurrentSection("trafficSignQuestions");
      question = trafficSignQuestions[questionsList[currentQuestionIndex]];
    } else {
      setCurrentSection("trafficRuleQuestions");
      question = trafficRuleQuestions[questionsList[currentQuestionIndex]];
    }

    console.log("QUESTION: ", question, "@ Index: ", currentQuestionIndex);
    setCurrentQuestion({
      Question: question.Question,
      Image: question.Image,
      OptionA: question.OptionA,
      OptionB: question.OptionB,
      OptionC: question.OptionC,
      OptionD: question.OptionD,
      Correct: question.Correct,
      Info: question.Info,
    });
    setImageLoaded(true);
  };

  /******* init *******/
  useEffect(() => {
    setCurrentTimerValue(currentTimerValue * 60);
    getCurrentQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /******* NextQuestion *******/
  useEffect(() => {
    if (currentQuestionIndex < questionsList.length) getCurrentQuestion();
    else
      navigate("/result", {
        state: {
          numOfTrafficSignQuestions: numOfTrafficSignQuestions,
          numOfTrafficRuleQuestions: numOfTrafficRuleQuestions,
          numOfSignQuestionsCorrect: numOfSignQuestionsCorrect,
          numOfRuleQuestionsCorrect: numOfRuleQuestionsCorrect,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestionIndex]);

  /******* timer *******/
  useEffect(() => {
    if (currentTimerValue <= 0 && timed) {
      navigate("/result", {
        state: {
          numOfTrafficSignQuestions: numOfTrafficSignQuestions,
          numOfTrafficRuleQuestions: numOfTrafficRuleQuestions,
          numOfSignQuestionsCorrect: numOfSignQuestionsCorrect,
          numOfRuleQuestionsCorrect: numOfRuleQuestionsCorrect,
          timed: timed,
          timerValue: currentTimerValue,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTimerValue]);

  /******* handlers *******/
  const handleImageLoadStart = () => {
    setImageLoaded(false);
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleHintClick = () => {
    setShowHint(true);
  };

  const handleNextClick = () => {
    handleImageLoadStart();
    setShowHint(false);
    setOptionSelected("None");
    setCurrentQuestionIndex(currentQuestionIndex + 1);
    if (optionSelected === currentQuestion.Correct) {
      if (currentSection === "trafficSignQuestions") {
        setNumOfSignQuestionsCorrect(numOfSignQuestionsCorrect + 1);
      } else {
        setNumOfRuleQuestionsCorrect(numOfRuleQuestionsCorrect + 1);
      }
    }
  };

  const handleMultipleChoiceOptionSelect = (id) => setOptionSelected(id);

  /**************************** Render ****************************/
  return (
    <body className={styles.body}>
      <div className={styles.quiz}>
        {/**************************** LeftSection ****************************/}
        <section className={styles.leftSection}>
          {/**************************** Image ****************************/}
          {currentQuestion?.Image ? (
            <div>
              <div
                className={styles.imageContainer}
                style={{
                  display: imageLoaded ? "flex" : "none",
                }}
              >
                <picture>
                  <source
                    srcSet={`/assets/QuestionsOpt/${currentQuestion.Image}.webp`}
                    type="image/webp"
                  />
                  <img
                  className={styles.questionImage}
                  alt=""
                  src={`/assets/Questions/${currentQuestion.Image}.png`}
                  srcSet={`
                          /assets/Questions/${currentQuestion.Image}.png?w=200 200w, 
                          /assets/Questions/${currentQuestion.Image}.png?w=250 250w, 
                          /assets/Questions/${currentQuestion.Image}.png?w=300 300w
                        `}
                  sizes="(max-width: 450px) 200px, (max-width: 750px) 250px, 300px"
                  loading="lazy"
                  onLoad={handleImageLoad}
                />
                </picture> 
              </div>

              <div
                className={styles.imageContainer}
                style={{
                  display: !imageLoaded ? "flex" : "none",
                }}
              >
                <ClipLoader color={"black"} size={50} />
              </div>
            </div>
          ) : (
            <Spinner />
          )}
          {/**************************** Buttons ****************************/}
          <div className={styles.leftSectionButtonContainer}>
            {showHint || optionSelected !== "None" ? (
              <></>
            ) : (
              <Button
                variant="primary"
                onClick={handleHintClick}
                className={`${styles.hintButton} title`}
              >
                Hint
              </Button>
            )}
            {optionSelected !== "None" ? (
              <Button
                variant="success"
                onClick={handleNextClick}
                className={`${styles.nextButton} title`}
                autoFocus
              >
                Next
              </Button>
            ) : (
              <></>
            )}
          </div>

          {/**************************** Hint ****************************/}
          <div className={`${styles.hintText} regularText`}>
            {(showHint || optionSelected !== "None") &&
            currentQuestion != null &&
            currentQuestion.Info != null
              ? currentQuestion.Info
              : ""}
          </div>
        </section>

        {/**************************** RightSection ****************************/}
        <section className={styles.rightSection}>
          <div className={styles.statusGroup}>
            <StatusGroup
              currentQuestion={currentQuestionIndex}
              max={
                parseInt(numOfTrafficSignQuestions) +
                parseInt(numOfTrafficRuleQuestions)
              }
              correctAmount={
                numOfSignQuestionsCorrect + numOfRuleQuestionsCorrect
              }
              incorrectAmount={
                currentQuestionIndex -
                (numOfSignQuestionsCorrect + numOfRuleQuestionsCorrect)
              }
              timed={timed}
              timerValue={currentTimerValue}
              setCurrentTimerValue={setCurrentTimerValue}
              maxTime={maxTimerValue}
            />
          </div>

          <div className={`${styles.question} subheading`}>
            {currentQuestion != null && currentQuestion.Question != null
              ? currentQuestion.Question
              : ""}
          </div>

          {optionSelected === "None" ? (
            <div className={styles.multipleChoiceQuestionsGroup}>
              <div
                onClick={() => handleMultipleChoiceOptionSelect("OptionA")}
                className={`${styles.multipleChoiceOption} regularText`}
              >
                {currentQuestion != null && currentQuestion.OptionA != null ? (
                  currentQuestion.OptionA
                ) : (
                  <Spinner />
                )}
              </div>
              <div
                onClick={() => handleMultipleChoiceOptionSelect("OptionB")}
                className={`${styles.multipleChoiceOption} regularText`}
              >
                {currentQuestion != null && currentQuestion.OptionB != null ? (
                  currentQuestion.OptionB
                ) : (
                  <Spinner />
                )}
              </div>
              <div
                onClick={() => handleMultipleChoiceOptionSelect("OptionC")}
                className={`${styles.multipleChoiceOption} regularText`}
              >
                {currentQuestion != null && currentQuestion.OptionC != null ? (
                  currentQuestion.OptionC
                ) : (
                  <Spinner />
                )}
              </div>
              <div
                onClick={() => handleMultipleChoiceOptionSelect("OptionD")}
                className={`${styles.multipleChoiceOption} regularText`}
              >
                {currentQuestion != null && currentQuestion.OptionD != null ? (
                  currentQuestion.OptionD
                ) : (
                  <Spinner />
                )}
              </div>
            </div>
          ) : (
            <div className={styles.resultContainer}>
              <div className={`${styles.correctAnswer} boldText`}>
                {currentQuestion != null ? (
                  currentQuestion[currentQuestion.Correct]
                ) : (
                  <Spinner />
                )}
              </div>

              {currentQuestion.Correct !== optionSelected ? (
                <div className={`${styles.incorrectAnswer} boldText`}>
                  {currentQuestion != null &&
                  currentQuestion[optionSelected] != null ? (
                    currentQuestion[optionSelected]
                  ) : (
                    <Spinner />
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </section>

        {/**************************** Mobile/Tablet Section ****************************/}
        <section className={styles.mobileSection}>
          {/**************************** Buttons ****************************/}
          <div className={styles.mobileLeftSectionButtonContainer}>
            {showHint || optionSelected !== "None" ? (
              <></>
            ) : (
              <Button
                variant="primary"
                onClick={handleHintClick}
                className={`${styles.hintButton} title`}
              >
                Hint
              </Button>
            )}
            {optionSelected !== "None" ? (
              <Button
                variant="success"
                onClick={handleNextClick}
                className={`${styles.nextButton} title`}
                autoFocus
              >
                Next
              </Button>
            ) : (
              <></>
            )}
          </div>

          {/**************************** Hint ****************************/}
          <div className={`${styles.mobileHintText} regularText`}>
            {(showHint || optionSelected !== "None") &&
            currentQuestion != null &&
            currentQuestion.Info != null
              ? currentQuestion.Info
              : ""}
          </div>
        </section>
      </div>

      <footer className={styles.footer}>
        <StatusGroup
          currentQuestion={currentQuestionIndex}
          max={
            parseInt(numOfTrafficSignQuestions) +
            parseInt(numOfTrafficRuleQuestions)
          }
          correctAmount={numOfSignQuestionsCorrect + numOfRuleQuestionsCorrect}
          incorrectAmount={
            currentQuestionIndex -
            (numOfSignQuestionsCorrect + numOfRuleQuestionsCorrect)
          }
          timed={timed}
          timerValue={currentTimerValue}
          setCurrentTimerValue={setCurrentTimerValue}
          maxTime={maxTimerValue}
        />
      </footer>
    </body>
  );
};

export default Quiz;
