export const trafficSignQuestions = [
    {
      Question: "What does this sign mean?",
      Image: "stopSign",
      OptionA: "No stopping.",
      OptionB: "Stop if necessary, go if road is clear.",
      OptionC: "Come to a complete stop.",
      OptionD: "Do not enter.",
      Correct: "OptionC",
      Info: "If there is no stop line or curb, stop at the edge of the intersection.",
    },

    {
      Question: "What does this sign mean?",
      Image: "yieldSign",
      OptionA: "No stopping.",
      OptionB: "You have the right-of-way.",
      OptionC: "You do not have the right-of-way. Give others the right-of-way.",
      OptionD: "Come to a stop, then yield.",
      Correct: "OptionC",
      Info: "Traffic in or close to the intersection has the right-of-way. Stop only when necessary.",
    },

    {
      Question: "What does this sign mean?",
      Image: "schoolZoneSign",
      OptionA: "School zone, reduce speed to 60 km/h.",
      OptionB: "School zone ahead, slow down and drive with extra caution.",
      OptionC: "School bus zone ahead.",
      OptionD: "Watch for pedestrians at cross-walk.",
      Correct: "OptionB",
      Info: "Always slow down and watch for children.",
    },

    {
      Question: "What does this sign mean?",
      Image: "railRoad",
      OptionA: "Pedestrian cross-walk up ahead.",
      OptionB: "Slow down, dead-end ahead.",
      OptionC: "Animal crossing in this area.",
      OptionD: "Rail road crossing. Tracks cross the road.",
      Correct: "OptionD",
      Info: "Trains may be approaching. Make sure to look both ways and be ready to come to a stop.",
    },

    {
      Question: "What does this sign mean?",
      Image: "noBikes",
      OptionA: "No bicycles allowed.",
      OptionB: "Watch for bicycles.",
      OptionC: "No bicycle parking.",
      OptionD: "School zone up ahead.",
      Correct: "OptionA",
      Info: "For the safety of everyone, bicycles are not permitted.",
    },

    {
      Question: "What does this sign mean?",
      Image: "parkingLimit",
      OptionA: "No parking.",
      OptionB: "Parking allowed for a maximum of the specified time during the posted hours and days.",
      OptionC: "No stopping.",
      OptionD: "Parking allowed for a minimum of the specified time during the posted hours and days.",
      Correct: "OptionB",
      Info: "These signs are posted in groups. Park in between the sign at the stated timings.",
    },

    {
      Question: "What does this sign mean?",
      Image: "noRightTurn",
      OptionA: "Turn right only on a red light.",
      OptionB: "No right turn allowed.",
      OptionC: "No right turn allowed on a red light.",
      OptionD: "Do not enter.",
      Correct: "OptionC",
      Info: "If the traffic light is red, you can not turn right.",
    },

    {
      Question: "What does this sign mean?",
      Image: "doNotEnterSign",
      OptionA: "Enter.",
      OptionB: "Do not enter the road.",
      OptionC: "Come to a complete stop.",
      OptionD: "Only bicycles are allowed to enter.",
      Correct: "OptionB",
      Info: "Do not enter under any sort of circumstances.",
    },
    
    {
      Question: "What does this sign mean?",
      Image: "noStanding",
      OptionA: "No stopping.",
      OptionB: "No Parking.",
      OptionC: "You may stand in the area between the signs.",
      OptionD: "Bus stop is to the right and left.",
      Correct: "OptionA",
      Info: "Stopping is only allowed if loading or unloading passengers. This sign is used in groups or pairs.",
    },
    
    {
      Question: "What does this sign mean?",
      Image: "noUTurn",
      OptionA: "No left turn.",
      OptionB: "You may go in the opposite direction.",
      OptionC: "No U-turn.",
      OptionD: "Do not enter.",
      Correct: "OptionC",
      Info: "Do not turn around and go in the opposite direction.",
    },
    
    {
      Question: "What does this sign mean?",
      Image: "noStoppingBetweenSigns",
      OptionA: "No stopping except to unload passengers.",
      OptionB: "There is a stop sign ahead.",
      OptionC: "Come to a complete stop.",
      OptionD: "No stopping at any time between the signs.",
      Correct: "OptionD",
      Info: "Besides no stopping, you are not allowed to load or unload passengers between signs that look like this.",
    },
    
    {
      Question: "What does this sign mean?",
      Image: "noLeftTurnDuringTimes",
      OptionA: "No left turn during days and times posted.",
      OptionB: "No right turn during days and times posted.",
      OptionC: "Left turn allowed after 5:00 p.m.",
      OptionD: "No left turn on Saturday or Sunday.",
      Correct: "OptionA",
      Info: "Carefully read the dates and times.",
    },
    
    {
      Question: "What does this sign mean?",
      Image: "noParkingBetweenSigns",
      OptionA: "Parking allowed on either side of the signs.",
      OptionB: "Parking available after 6:00 p.m.",
      OptionC: "No Parking allowed between the signs.",
      OptionD: "There is parking available on either side of the arrows.",
      Correct: "OptionC",
      Info: "When used in pairs, there is no parking between the signs unless you or loading or unloading people, or merchandise.",
    },

    {
      Question: "What does this sign mean?",
      Image: "noThroughTraffic",
      OptionA: "You are allowed to go straight through the intersection.",
      OptionB: "You are not allowed to go straight through the intersection.",
      OptionC: "You are not allowed to turn right or left.",
      OptionD: "Stop at the intersection ahead.",
      Correct: "OptionB",
      Info: "You are not allowed to drive through the intersection so be prepared to turn.",
    },

    {
      Question: "What does this sign mean?",
      Image: "50Ahead",
      OptionA: "The speed limit is 50 km/h.",
      OptionB: "The speed limit is 50 km/h ahead.",
      OptionC: "It is 50 km to the next rest area.",
      OptionD: "The speed limit is 50 km/h for a distance of 50 km.",
      Correct: "OptionB",
      Info: "The speed limit ahead is changing from what it is to 50 km/h.",
    },

    {
      Question: "What does this sign mean?",
      Image: "oneWay",
      OptionA: "Reduce speed ahead.",
      OptionB: "Exit to the right.",
      OptionC: "One way traffic in the direction indicated by the arrow.",
      OptionD: "Two-way traffic permitted.",
      Correct: "OptionC",
      Info: "You can only travel in the direction of the arrow.",
    },

    {
      Question: "What does this sign mean?",
      Image: "noPedestrians",
      OptionA: "No pedestrians allowed on the roadway.",
      OptionB: "Pedestrians may not cross the road.",
      OptionC: "There is no crosswalk in the area.",
      OptionD: "Do not enter- construction zone.",
      Correct: "OptionA",
      Info: "Pedestrians are not allowed on the road.",
    },

    {
      Question: "What does this sign mean?",
      Image: "noPassing",
      OptionA: "No passing on this road.",
      OptionB: "You may pass on this road.",
      OptionC: "Two-way traffic ahead.",
      OptionD: "Do not enter.",
      Correct: "OptionA",
      Info: "For safety reasons, there is no passing on this roadway.",
    },

    {
      Question: "What does this sign mean?",
      Image: "leftLaneForPassing",
      OptionA: "Right turn and passing lane ahead.",
      OptionB: "Keep to the right except when passing on two way road sections.",
      OptionC: "Road turns to the right.",
      OptionD: "Merge ahead.",
      Correct: "OptionB",
      Info: "You are permitted to pass on the left; otherwise, keep to the right.",
    },

    {
      Question: "What does this sign mean?",
      Image: "pedestrianCrossing",
      OptionA: "Stop and yield right-of-way to pedestrians at crosswalk. Do not pass from sign to crossing.",
      OptionB: "Railroad crossing ahead, no pedestrians allowed.",
      OptionC: "Pedestrians or vehicles may not enter the roadway.",
      OptionD: "Deer crossing ahead.",
      Correct: "OptionA",
      Info: "You are approaching a pedestrian crosswalk. Slow down, yield to pedestrians and be prepared to stop.",
    },
    
    {
      Question: "What does this sign mean?",
      Image: "lanesTravelDirection",
      OptionA: "If you are in lanes 1, 2 or 3 you must only go in the direction of that lane's arrow.",
      OptionB: "If you are in lane 1 you may go straight and left.",
      OptionC: "You can jump off the highway.",
      OptionD: "You are not allowed to go through the signs.",
      Correct: "OptionA",
      Info: "These signs are either painted on the roadway or hung above at intersections. They tell drivers the direction they must travel for the lane they are in.",
    },

    {
      Question: "What does this sign mean?",
      Image: "twoWayLeftTurns",
      OptionA: "Lane is not for left turns.",
      OptionB: "Lane is only for two-way left turns.",
      OptionC: "Turn left or right.",
      OptionD: "No U-turn is allowed.",
      Correct: "OptionB",
      Info: "You are not allowed to drive through the intersection so be prepared to turn.",
    },

    {
      Question: "What does this sign mean?",
      Image: "diamondLaneBycicles",
      OptionA: "Lanes are for pedestrians only, no vehicles permitted during days and times posted.",
      OptionB: "Lanes are for loading and unloading passengers only during days and times posted.",
      OptionC: "Lanes are for 5 or more passengers during days and times posted.",
      OptionD: "Lanes are for 3 or more passengers in the vehicles shown, plus bikes, during the days and times shown or all the time (depending on the sign).",
      Correct: "OptionD",
      Info: "These lanes are for bicycles or specific vehicles that carry 3 or more people, for the times indicated or all the time if no time is shown.",
    },

    {
      Question: "What does this sign mean?",
      Image: "rightOfWay",
      OptionA: "Crosswalk ahead, watch for pedestrians crossing.",
      OptionB: "Intersection ahead, direction of arrow indicates who has the right-of-way.",
      OptionC: "Railroad Crossing is ahead.",
      OptionD: "Quiet please, silent zone is approaching.",
      Correct: "OptionB",
      Info: "When approaching an intersection with this sign, the direction of the arrow indicates who has right-of-way.",
    },

    {
      Question: "What does this sign mean?",
      Image: "narrowBridge",
      OptionA: "The road narrows ahead.",
      OptionB: "The two-way road curves ahead.",
      OptionC: "A narrow bridge is ahead.",
      OptionD: "Slower traffic, move to the right.",
      Correct: "OptionC",
      Info: "This symbol represents a bridge and that the bridge is narrower than the roadway.",
    },

    {
      Question: "What does this sign mean?",
      Image: "narrowPavement",
      OptionA: "There is a narrow bridge ahead.",
      OptionB: "The road narrows ahead.",
      OptionC: "Two-lane roadway ends.",
      OptionD: "Merge with oncoming traffic.",
      Correct: "OptionB",
      Info: "The pavement will become narrower ahead.",
    },

    {
      Question: "What does this sign mean?",
      Image: "sharpTurn",
      OptionA: "Exit to the right.",
      OptionB: "Sharp turn here.",
      OptionC: "Crosswalk ahead.",
      OptionD: "Railway Crossing.",
      Correct: "OptionB",
      Info: "These arrows are called chevrons. They warn you about sharp turns and are posted in groups to guide drivers.",
    },

    {
      Question: "What does this sign mean?",
      Image: "stopSignAhead",
      OptionA: "Intersection ahead.",
      OptionB: "Stop sign ahead.",
      OptionC: "Stop at cross-walk ahead.",
      OptionD: "Traffic light ahead.",
      Correct: "OptionB",
      Info: "There is a stop sign ahead so slow down.",
    },

    {
      Question: "What does this sign mean?",
      Image: "hazard",
      OptionA: "Sharp turn ahead.",
      OptionB: "Single lane ahead.",
      OptionC: "Hazard close to the edge of the road.",
      OptionD: "Crosswalk ahead.",
      Correct: "OptionC",
      Info: "An island or something close to the road is a hazard. Downward lines indicate the direction to pass on.",
    },

    {
      Question: "What does this sign mean?",
      Image: "median",
      OptionA: "Slower traffic keep to the right.",
      OptionB: "Highway starts ahead.",
      OptionC: "The two-way road splits with a median in-between.",
      OptionD: "Police ahead.",
      Correct: "OptionC",
      Info: "The two-way road will be splitting with a median ahead. Each side of the road is one-way traffic.",
    },

    {
      Question: "What does this sign mean?",
      Image: "noMedian",
      OptionA: "Bridge ahead.",
      OptionB: "Gravel road ahead.",
      OptionC: "The two-way road splits with a median in-between.",
      OptionD: "The two-way road is not divided with a median ahead.",
      Correct: "OptionD",
      Info: "There is no median in the middle of the 2-way road.",
    },

    {
      Question: "What does this sign mean?",
      Image: "windingRoad",
      OptionA: "Winding road ahead.",
      OptionB: "sharp turn ahead.",
      OptionC: "Slippery road ahead.",
      OptionD: "Speed bumps ahead.",
      Correct: "OptionA",
      Info: "The road is winding ahead which may obstruct your ability to see other vehicles.",
    },

    {
      Question: "What does this sign mean?",
      Image: "slipperyRoad",
      OptionA: "Bridge ahead is icy.",
      OptionB: "Slippery when wet.",
      OptionC: "You are entering a snowmobile area.",
      OptionD: "Drag racing ahead.",
      Correct: "OptionB",
      Info: "The road may be slippery. Drive with caution.",
    },

    {
      Question: "What does this sign mean?",
      Image: "rightLaneEnds",
      OptionA: "Road has a bend to the right.",
      OptionB: "Road turns sharply to the right.",
      OptionC: "Left lane ends ahead.",
      OptionD: "Right lane ends ahead.",
      Correct: "OptionD",
      Info: "The right lane ends ahead and you are required to merge with traffic in the lane to the left if you are in the right lane.",
    },

    {
      Question: "What does this sign mean?",
      Image: "bicycleCrossing",
      OptionA: "No Bicycle allowed.",
      OptionB: "School zone ahead.",
      OptionC: "Bicycle parking ahead.",
      OptionD: "Bicycle Crossing ahead.",
      Correct: "OptionD",
      Info: "Slow down and cautiously proceed.",
    },

    {
      Question: "What does this sign mean?",
      Image: "liftingBridge",
      OptionA: "BrokenBridge ahead.",
      OptionB: "Bridge under construction ahead.",
      OptionC: "Lifting bridge ahead.",
      OptionD: "Ramp up ahead.",
      Correct: "OptionC",
      Info: "The bridge ahead will either swing or lift when letting boats pass.",
    },

    {
      Question: "What does this sign mean?",
      Image: "pavedRoadEnds",
      OptionA: "Paved road ends.",
      OptionB: "Gravel Road ends.",
      OptionC: "Icy road starts.",
      OptionD: "Flooded road starts.",
      Correct: "OptionA",
      Info: "Reduce speed as the paved road ends and is replaced by a gravel road.",
    },

    {
      Question: "What does this sign mean?",
      Image: "mergingLanes",
      OptionA: "Intersection ahead.",
      OptionB: "Two-lane road ahead.",
      OptionC: "Two lanes will merge into one. Drivers in both lanes are responsible for merging safely.",
      OptionD: "Two lanes will merge into one. Driver on the  right has right-of-way.",
      Correct: "OptionC",
      Info: "Two lanes merging into one. Drivers in both lanes are responsible for merging.",
    },

    {
      Question: "What does this sign mean?",
      Image: "medianEnds",
      OptionA: "Divided highway begins.",
      OptionB: "Divided highway ends.",
      OptionC: "Speed-trap ahead.",
      OptionD: "No sidewalk ahead.",
      Correct: "OptionB",
      Info: "Stick to the right as traffic will be going both ways.",
    },

    {
      Question: "What does this sign mean?",
      Image: "snowMobileCrossing",
      OptionA: "Snowmobile parking.",
      OptionB: "Snowmobile crossing.",
      OptionC: "Snowmobiles are allowed only.",
      OptionD: "Skiing area.",
      Correct: "OptionB",
      Info: "Drive cautiously as snowmobiles cross this area.",
    },

    {
      Question: "What does this sign mean?",
      Image: "dangerSharpTurn",
      OptionA: "Danger, construction zone ahead.",
      OptionB: "Detour ahead.",
      OptionC: "Sharp turn in the direction of the arrow.",
      OptionD: "Road closed.",
      Correct: "OptionC",
      Info: "The checkered pattern represents danger. slow down and turn in the direction of the arrow with caution.",
    },

    {
      Question: "What does this sign mean?",
      Image: "deerCrossing",
      OptionA: "Hunting area.",
      OptionB: "Animal zoo up ahead.",
      OptionC: "Deer may cross the road.",
      OptionD: "Watch for deer poachers.",
      Correct: "OptionC",
      Info: "Deer may cross the road in this area. Slow down and watch your surroundings.",
    },

    {
      Question: "What does this sign mean?",
      Image: "trafficLightSign",
      OptionA: "Broken traffic light ahead.",
      OptionB: "Speed camera ahead.",
      OptionC: "City zone ahead.",
      OptionD: "Traffic lights ahead.",
      Correct: "OptionD",
      Info: "You are approaching a traffic light. Slow down and be prepared to stop.",
    },

    {
      Question: "What does this sign mean?",
      Image: "steepHill",
      OptionA: "There is a steep hill ahead.",
      OptionB: "You are in a mountain zone.",
      OptionC: "Caution, trucks ahead.",
      OptionD: "Construction zone ahead.",
      Correct: "OptionA",
      Info: "The road has a steep hill ahead. Slow down and be prepared to shift into a lower gear and slow down.",
    },

    {
      Question: "What does this sign mean?",
      Image: "truckEnteranceRight",
      OptionA: "Trucks approaching from right.",
      OptionB: "Speeding trucks ahead.",
      OptionC: "Construction zone ahead.",
      OptionD: "Off-road trucks only.",
      Correct: "OptionA",
      Info: "Trucks entrance is on the side of the road where the truck is drawn. Proceed with caution.",
    },

    {
      Question: "What does this sign mean?",
      Image: "clearance",
      OptionA: "The road ends in 3.9 km.",
      OptionB: "Only vehicles over 3.9m permitted ahead.",
      OptionC: "There is a maximum 3.9m clearance ahead on the overpass.",
      OptionD: "Divided highway ends in 3.9 km.",
      Correct: "OptionC",
      Info: "If driving a tall vehicle you may not be able to pass. Take not of clearances before approaching underpasses.",
    },

    {
      Question: "What does this sign mean?",
      Image: "pedestrians",
      OptionA: "Men at work ahead.",
      OptionB: "Pedestrian crossing ahead.",
      OptionC: "School zone ahead.",
      OptionD: "Share the road with pedestrians.",
      Correct: "OptionD",
      Info: "Watch for pedestrians as the roadway is meant to be shared. Drive cautiously.",
    },

    {
      Question: "What does this sign mean?",
      Image: "rocksFalling",
      OptionA: "Watch out for fallen rocks.",
      OptionB: "Steep hill, shift into lower gear.",
      OptionC: "Construction zone ahead.",
      OptionD: "Large hail stone area.",
      Correct: "OptionA",
      Info: "Rocks could be falling so drive carefully and be prepared to avoid a collision.",
    },

    {
      Question: "What does this sign mean?",
      Image: "hiddenBusStop",
      OptionA: "Railroad tracks ahead.",
      OptionB: "School crossing ahead.",
      OptionC: "Ambulance drop-off ahead.",
      OptionD: "You are approaching a hidden school bus stop.",
      Correct: "OptionD",
      Info: "Be very cautious, watch for children and be prepared to stop for a school bus with flashing red lights.",
    },

    {
      Question: "What does this sign mean?",
      Image: "school",
      OptionA: "School crossing or school crossing ahead.",
      OptionB: "Hidden school bus stop ahead.",
      OptionC: "Children must be accompanied by an adult in the area.",
      OptionD: "Market ahead.",
      Correct: "OptionA",
      Info: "Watch for children crossing and follow the direction of the crossing guard. These signs may be neon yellow or could be blue.",
    },

    {
      Question: "What does this sign mean?",
      Image: "speedRamp",
      OptionA: "There is a winding road for 40 km.",
      OptionB: "Maximum Safe speed to drive on ramp is 40 km/h.",
      OptionC: "Minimum Safe speed to drive on ramp is 40 km/h.",
      OptionD: "Next rest stop is in 40 km.",
      Correct: "OptionB",
      Info: "All ramps have a maximum safe speed you can travel. Obey these maximums.",
    },

    {
      Question: "What does this sign mean?",
      Image: "floodingRoad",
      OptionA: "Bridge ahead.",
      OptionB: "Bridge ices.",
      OptionC: "Road may have water flowing over it.",
      OptionD: "Road ends at lake.",
      Correct: "OptionC",
      Info: "Some roads are near high water areas where the road may get covered with water. Reduce speed and drive carefully.",
    },

    {
      Question: "What does this sign mean?",
      Image: "bumpyRoad",
      OptionA: "Mountain zone ahead.",
      OptionB: "Bumpy or uneven road ahead.",
      OptionC: "Hilly area ahead.",
      OptionD: "Falling rocks ahead.",
      Correct: "OptionB",
      Info: "Slow down and keep both hands on the steering wheel for the best control of your vehicle and to avoid sudden shifts.",
    },
    
    {
      Question: "What does this sign mean?",
      Image: "trafficController",
      OptionA: "Person controlling traffic ahead.",
      OptionB: "Roadwork ahead.",
      OptionC: "Survey crew assessing road ahead.",
      OptionD: "Construction zone ahead.",
      Correct: "OptionA",
      Info: "Slow down and pay attention to instructions from the traffic control person ahead.",
    },
    
    {
      Question: "What does this sign mean?",
      Image: "roadWork",
      OptionA: "Fallen rock ahead.",
      OptionB: "Snow removal ahead.",
      OptionC: "Road work occurring ahead.",
      OptionD: "Sand quarry ahead.",
      Correct: "OptionC",
      Info: "Reduce Speed, drive with caution and watch for construction personnel working on the road ahead.",
    },
    
    {
      Question: "What does this sign mean?",
      Image: "slowStop",
      OptionA: "Stop and drive slowly through campground.",
      OptionB: "Obey the crossing guard with the signs they hold up.",
      OptionC: "Slow down and be prepared to stop.",
      OptionD: "Slow down, stop and exit the construction zone.",
      Correct: "OptionC",
      Info: "These signs may be held by road work personnel. Drive slowly and be prepared to stop.",
    },
    
    {
      Question: "What does this sign mean?",
      Image: "detour",
      OptionA: "The road detours so follow this sign until you come to the regular road.",
      OptionB: "Follow the detour marker only if you choose not to take the regular road.",
      OptionC: "Merge with road that closed.",
      OptionD: "Lane close ahead. Slow down and merge with traffic.",
      Correct: "OptionA",
      Info: "Follow this sign through the detour until you come to the regular road.",
    },
            
    {
      Question: "What does this sign mean?",
      Image: "slowVehicle",
      OptionA: "Speed in area is very slow, less than 40 km/h.",
      OptionB: "Slow-moving vehicle on road, travelling less than 40 km/h.",
      OptionC: "Snow removal vehicle ahead.",
      OptionD: "Danger, keep out, road under construction.",
      Correct: "OptionB",
      Info: "All vehicles moving less than 40 km/hour must display a slow moving vehicle sign at rear if driving on a road.",
    },
    
  ];

  export const trafficRuleQuestions = [
    {
      Question: "When are you required to wear a seatbelt?",
      Image: "seatBelt",
      OptionA: "Wearing a seatbelt is optional.",
      OptionB: "Only drivers are required.",
      OptionC: "Only passengers are required.",
      OptionD: "Both driver and passengers are required to wear a seatbelt.",
      Correct: "OptionD",
      Info: "Drivers will receive a fine up to $1000 and 2 demerit points for not wearing a seatbelt. Drivers must ensure all passengers under the age of 16 are properly buckled up. Unbuckled passengers 16 years and older can be fined.",
    },

    {
      Question: "What does the law state about seatbelts and children 9-18kg (20-40 lbs.)?",
      Image: "childSeat",
      OptionA: "All infants, toddlers and children under 8 must be in a booster seat.",
      OptionB: "All infants, toddlers and children under 8 must be in a rear-facing child car seat.",
      OptionC: "All infants, toddlers and children under 8 must wear protective head gear.",
      OptionD: "All toddlers 9-18kg must be in an approved child car seat that is properly attached.",
      Correct: "OptionD",
      Info: "A child's weight and/or age determines what type of car seat is required. It is most important that all child car seats meet safety standards and are properly secured.",
    },

    {
      Question: "How far away must headlights and rear lights be seen?",
      Image: "carLights",
      OptionA: "From 50m away.",
      OptionB: "From 150m away.",
      OptionC: "From 1150m away.",
      OptionD: "They must be seen clearly in the dark.",
      Correct: "OptionB",
      Info: "You must also have your rear license plate illuminated with lighting when headlights are on. Ensure headlights are on 1/2 an hour before sunset and leave them on for 1/2 an hour after sunrise.",
    },

    {
      Question: "When using high-beam lights, when do you have to switch to low-beam lights?",
      Image: "beamLights",
      OptionA: "Within 150m of oncoming vehicles.",
      OptionB: "Within 50m of oncoming vehicles.",
      OptionC: "You do not have to switch.",
      OptionD: "If the oncoming car puts their high-beams on, you do not have to switch.",
      Correct: "OptionA",
      Info: "Use low-beam lights when you are less than 60m behind another vehicle and within 150m of an oncoming vehicle. Never use parking lights for driving. They are only for parking.",
    },

    {
      Question: "Why must you use signals when turning?",
      Image: "turningLights",
      OptionA: "To inform other drivers of what you want to do.",
      OptionB: "To inform pedestrians of what you want to do.",
      OptionC: "To send out an alert of your intentions.",
      OptionD: "All of the above.",
      Correct: "OptionD",
      Info: "Failing to use proper signals can result in a fine and demerit points. Always signal to inform others of your intention. Follow all rules for turning.",
    },

    {
      Question: "Unless posted in cities, towns, villages and built-up areas, the maximum speed limit is?",
      Image: "speedLimit",
      OptionA: "50 km/h.",
      OptionB: "60 km/h.",
      OptionC: "70 km/h.",
      OptionD: "80 km/h.",
      Correct: "OptionA",
      Info: "Where there is no speed limit posted, the limit is 80 km/h outside these areas.",
    },

    {
      Question: "What are you required to do if a police officer signals you to pull over?",
      Image: "pullOver",
      OptionA: "Slow down, safely pull over in the left lane and come to a complete stop.",
      OptionB: "Slow down, safely pull over in the right lane and come to a complete stop.",
      OptionC: "Slow down and stop in the lane you are in.",
      OptionD: "Signal and stop at the next intersection and wait for the police officer.",
      Correct: "OptionB",
      Info: "Do not get out of your vehicle. Wait for the police officer to come to you.",
    },

    {
      Question: "How much time do you have to surrender your license, vehicle permit (or copy) and insurance when asked by a police officer?",
      Image: "id",
      OptionA: "Immediately.",
      OptionB: "Within 1 hour.",
      OptionC: "Never, except when you go to court.",
      OptionD: "You do not have to, they will look it up in the system.",
      Correct: "OptionA",
      Info: "Failure to represent your documents will result in a fine.",
    },

    {
      Question: "How much space should you have between you and any vehicle you are following",
      Image: "space",
      OptionA: "No rule applies and you will not get a fine or demerit points as long as caution is used.",
      OptionB: "There is a rule of 20 seconds but it is for motorcycle drivers only.",
      OptionC: "At least 12 seconds so you can see around the vehicle ahead and also have enough time to stop.",
      OptionD: "At least 2 seconds so you can see around the vehicle ahead and also have enough time to stop.",
      Correct: "OptionD",
      Info: "If driving conditions are not ideal,add more space especially if driving in bad weather or following larger vehicles, motorcycles, or when you have a heavy load. Use markers on the road to help determine correct following distance.",
    },

    {
      Question: "If 2 vehicles come to an uncontrolled intersection at the same time, who has the right-of-way?",
      Image: "rightOfWay",
      OptionA: "The vehicle on the right.",
      OptionB: "The vehicle on the left.",
      OptionC: "The vehicle turning right.",
      OptionD: "The vehicle turning left.",
      Correct: "OptionA",
      Info: "The driver on the left must let the driver on the right go first at intersections with no signs or lights.",
    },

    {
      Question: "If you are turning left at an uncontrolled intersection and a pedestrian is crossing your path, who has the right of way?",
      Image: "uncontrolledIntersectionPedestrian",
      OptionA: "You do. Come to a complete stop and make your turn.",
      OptionB: "Any other car at the intersection that is turning right.",
      OptionC: "You must yield the right-of-way to approaching traffic and/or to pedestrians crossing.",
      OptionD: "Whoever is more in a hurry goes first.",
      Correct: "OptionC",
      Info: "Always yield the right-of-way to pedestrians no matter the direction you are turning.",
    },
    
    {
      Question: "If you approach an intersection on a main road that is blocked with traffic, what should you do?",
      Image: "intersectionTraffic",
      OptionA: "Stop before entering the intersection.",
      OptionB: "Move up so cars behind you can move forward.",
      OptionC: "Slowly proceed through the intersection.",
      OptionD: "Turn left or right to avoid the heavy traffic back-up.",
      Correct: "OptionA",
      Info: "Only proceed after stopping and when the way is clear. Do not allow yourself to be stuck in the intersection as the lights turn red.",
    },
   
    {
      Question: "How much room do cyclists need on either side of themselves as a safety zone?",
      Image: "bikeSpace",
      OptionA: "4 m.",
      OptionB: "3 m.",
      OptionC: "2 m.",
      OptionD: "1 m.",
      Correct: "OptionD",
      Info: "Failing to leave the required amount of space could result in a fine and 2 demerit points and jeopardize the safety of the cyclist. Also, opening a door of a parked vehicle into the path of a cyclist or traffic could result in 3 demerit points and a fine.",
    },

    {
      Question: "What challenges do commercial vehicles have that are dangerous for other vehicles?",
      Image: "commercialVehicles",
      OptionA: "They have small blind spots.",
      OptionB: "They make wide turns.",
      OptionC: "They roll forward after stopping.",
      OptionD: "They block large amounts of snow and slush from your windshield.",
      Correct: "OptionB",
      Info: "Always leave enough road space around large commercial vehicles. Be aware of what they can and cannot do.",
    },

    {
      Question: "Coming to a complete stop at an intersection is required, but where do you stop if there is no stop line, crosswalk, or sidewalk?",
      Image: "noStopLine",
      OptionA: "You stop right beside the stop sign.",
      OptionB: "You stop right before the stop sign.",
      OptionC: "You stop at the edge of the intersection.",
      OptionD: "You stop a little into the intersection so that you can see traffic and pedestrians.",
      Correct: "OptionC",
      Info: "You must also wait for the intersection to clear before entering it.",
    },

    {
      Question: "When do you stop for school buses if there is a median?",
      Image: "schoolBusStop",
      OptionA: "Whenever you approach and see one.",
      OptionB: "Never, because they will stop for you.",
      OptionC: "Whenever they stop.",
      OptionD: "Only if you are behind a stopped bus which has its upper red alternating lights flashing.",
      Correct: "OptionD",
      Info: "School buses also have flashing stop signs that swing out from the driver's side, notifying vehicles to stop. Stay back the required distance.",
    },

    {
      Question: "If there is no median, how far in front and behind a school bus are you required to stop when its lights are flashing?",
      Image: "schoolBusStopNoMedian",
      OptionA: "At least 20 m behind and far enough in front for children to exit the bus and cross safely.",
      OptionB: "At least 20 ft behind and far enough in front for children to exit the bus and cross safely.",
      OptionC: "At least 10 m behind and far enough in front for children to exit the bus and cross safely.",
      OptionD: "At least 10 ft behind and far enough in front for children to exit the bus and cross safely.",
      Correct: "OptionA",
      Info: "Only proceed when the bus lights have stopped flashing or the bus has moved.",
    },

    {
      Question: "It is illegal to not stop behind a stopped school bus with alternating flashing red lights. If you do not stop what can happened on a first offence?",
      Image: "schoolBusFine",
      OptionA: "You can be fined $40-$200 and get 2 demerit points.",
      OptionB: "You can be fined $400-$2,000 and get 6 demerit points.",
      OptionC: "You can be fined $4,000-$20,000.",
      OptionD: "You will not be fined for the first offence; you will only receive a warning.",
      Correct: "OptionB",
      Info: "You can get 6 demerit points for a first offence and a fine of $400-$2,000.",
    },

    {
      Question: "You must share the road with motorcycles, cyclists, commercial vehicles, pedestrians, farm machinery, and buses?",
      Image: "shareRoad",
      OptionA: "The above statement is false.",
      OptionB: "The above statement is true.",
      OptionC: "All of the above are correct except for farm machinery.",
      OptionD: "All of the above are correct except for pedestrians because they are not in a vehicle.",
      Correct: "OptionB",
      Info: "Failing to share the road with the correct things can result in a fine and demerit points.",
    },

    {
      Question: "Can you make a right turn on a red light?",
      Image: "rightTurnRedLight",
      OptionA: "Yes.",
      OptionB: "No.",
      OptionC: "Only on major intersections.",
      OptionD: "Only on minor intersections.",
      Correct: "OptionA",
      Info: "Ensure you first come to a complete stop and that your way is clear before making your turn.",
    },

    {
      Question: "How do you make a left turn from a two-way road onto a two-way road?",
      Image: "leftTurnTwoWayRoad",
      OptionA: "Turn from the right lane making a smooth arc into the right curb lane.",
      OptionB: "Turn from the right lane making a smooth arc into the lane right of the center line.",
      OptionC: "Turn from the closest lane to the center dividing line into the right curb lane.",
      OptionD: "Turn from the closest lane to the center dividing line  making a smooth arc into the lane to the right of the center dividing lane.",
      Correct: "OptionD",
      Info: "Unless otherwise posted left turns are made from far left lane. Signal, check all directions and ensure way is clear. After turning move into the right curb lane when it is clear to do so.",
    },

    {
      Question: "How do you make a left turn from a two-way road onto a two lane one-way road?",
      Image: "leftTurn",
      OptionA: "Turn from the far lane into the left curb lane.",
      OptionB: "Turn from the far lane into the right curb lane.",
      OptionC: "Turn from the far right lane into the left curb lane.",
      OptionD: "Turn from the far right lane into the right curb lane.",
      Correct: "OptionA",
      Info: "Always signal in advance, check all directions and then proceed slowly making a smooth arc.",
    },

    {
      Question: "Why is it a bad idea to turn your steering wheel to the left while waiting to make a left turn at an intersection?",
      Image: "steeringWheelLeft",
      OptionA: "In case you need to turn right-it will be harder to steer out of the turn.",
      OptionB: "It is a good idea.",
      OptionC: "Another vehicle can push your vehicle into oncoming traffic.",
      OptionD: "None of the above.",
      Correct: "OptionC",
      Info: "Only turn your steering wheel to the left when you can make the turn.",
    },

    {
      Question: "What do the white arrow markings on the middle of the road mean in the following picture?",
      Image: "roadMarkings",
      OptionA: "There is a left turn lane in the center for those travelling north.",
      OptionB: "There is a left turn lane in the center for those travelling south.",
      OptionC: "No idea.",
      OptionD: "There is a two-way left turn lane in the center for traffic going in both directions.",
      Correct: "OptionD",
      Info: "Signal and move into the left turn lane, make your turn carefully and only when the way is clear.",
    },

    {
      Question: "Can you turn left on a red light from a one-way road onto a one-way road?",
      Image: "leftTurnRedLightOneWayRoad",
      OptionA: "Yes, if you signal, stop first in the farthest left lane and then proceed when the way is clear.",
      OptionB: "No.",
      OptionC: "No you cannot as the law states so.",
      OptionD: "Left turns are only permitted from two-way roads to two-way roads on a green light.",
      Correct: "OptionA",
      Info: "Ensure you yield to pedestrians and traffic after first coming to a complete stop.",
    },

    {
      Question: "To make a U-turn you must be able to see how far in both directions?",
      Image: "uTurn",
      OptionA: "50 m.",
      OptionB: "100 m.",
      OptionC: "150 m.",
      OptionD: "200 m.",
      Correct: "OptionC",
      Info: "Never make a U-turn on a curve, hill, railway crossing, bridge, tunnel, or where your view is blocked. It is illegal to do so.",
    },

    {
      Question: "When can you remove your seatbelt while driving?",
      Image: "seatBelt",
      OptionA: "When you are backing up.",
      OptionB: "When you are the only person in the car.",
      OptionC: "When you feel like it.",
      OptionD: "When you are on a country road.",
      Correct: "OptionA",
      Info: "To see properly while backing up, you can remove your seatbelt to turn your body. Remember to put it back on as soon as you move forward.",
    },

    {
      Question: "When parking uphill with a curb, what should you do?",
      Image: "parkUphillCurb",
      OptionA: "Turn your tires to the left to catch the curb if your vehicle rolls backward.",
      OptionB: "Turn your tires to the right to catch the curb if your vehicle rolls backward.",
      OptionC: "Make your tires straight and parallel with the curb.",
      OptionD: "The direction of your tires does not matter as long as you set the parking brake.",
      Correct: "OptionA",
      Info: "Turn your tires to the left and set your parking break, always do both.",
    },

    {
      Question: "Who has the right-of-way in a roundabout?",
      Image: "roundabout",
      OptionA: "Traffic approaching the roundabout.",
      OptionB: "Traffic in the roundabout.",
      OptionC: "Traffic turning right in the roundabout.",
      OptionD: "Traffic turning left in the roundabout.",
      Correct: "OptionB",
      Info: "Slow down when approaching the yield line at roundabouts. Only stop at yield line if necessary. Enter when clear and travel with traffic flow in a counter-clockwise direction.",
    },

    {
      Question: "When can you pass on a shoulder?",
      Image: "shoulder",
      OptionA: "Only if a vehicle is turning left and you have an unpaved shoulder on the right.",
      OptionB: "Only if a vehicle is turning left and you have a paved shoulder on the right.",
      OptionC: "Whenever the vehicle in front of you is moving under 40 km/h.",
      OptionD: "Whenever you can do so safely.",
      Correct: "OptionB",
      Info: "Passing is generally done from the left but you can pass on the right in this case if there is another vehicle turning left and only if the shoulder is paved.",
    },

    {
      Question: "Two solid painted lines on a roadway as in this diagram do which of the following?",
      Image: "yellowPaintedLines",
      OptionA: "Help in calculating distances from one exit to the next.",
      OptionB: "Act as left and right turn lane markings.",
      OptionC: "No idea.",
      OptionD: "Are warnings to guide drivers away from fixed obstacles like islands, bridges, etc.",
      Correct: "OptionD",
      Info: "Steer clear of solid painted road markings that are around fixed objects to avoid a collision.",
    },

    {
      Question: "What do double solid pavement markings mean?",
      Image: "doubleSolidPavement",
      OptionA: "Vehicles travelling on the left may pass when the way is clear.",
      OptionB: "Vehicles travelling on the right may pass when the way is clear.",
      OptionC: "Both vehicles may pass in either direction when the way is clear.",
      OptionD: "No vehicles may pass in either direction.",
      Correct: "OptionD",
      Info: "Solid yellow lines divide traffic going in opposite directions.",
    },

    {
      Question: "What must you do when changing lines?",
      Image: "changingLines",
      OptionA: "Check mirrors and blind spots for space, signal, check for space again, steer into lane.",
      OptionB: "Signal and steer into lane slowly.",
      OptionC: "Signal and steer into lane quickly to get into lane safely.",
      OptionD: "Sound horn, signal, and move slowly.",
      Correct: "OptionA",
      Info: "Checks and double checks as well as steering into the new lane at a gradual or slightly greater speeds are necessary for proper and safe turns.",
    },

    {
      Question: "What must drivers do at pedestrian crossovers and school crossing?",
      Image: "driversPedestrianCrossingAndSchoolCrossings",
      OptionA: "Nothing, drive normally.",
      OptionB: "Stop and wait until all pedestrians, including school crossing guards have completely crossed the road.",
      OptionC: "Slow down and wait until all pedestrians have almost crossed the road.",
      OptionD: "Slow down and proceed with caution.",
      Correct: "OptionB",
      Info: "Drivers can be fined up to $500 and get 3 demerit points for not abiding with the law in this scenario.",
    },

    {
      Question: "What are passing or climbing lanes for?",
      Image: "passingClimbingLanes",
      OptionA: "Nothing.",
      OptionB: "They are for vehicles that have trouble climbing hills/passing lanes.",
      OptionC: "They help thin out traffic by providing and extra lane.",
      OptionD: "They allow slower vehicles to move into the right so faster one pass on the left.",
      Correct: "OptionD",
      Info: "Advance notice of passing lanes is provided as are signs warning when these lanes end.",
    },

    {
      Question: "When can you pass on the right?",
      Image: "passOnTheRight",
      OptionA: "Never.",
      OptionB: "Always.",
      OptionC: "On one-way or multi-lane roads, when passing a street car or a vehicle turning left.",
      OptionD: "On any type of road providing there is an unpaved shoulder.",
      Correct: "OptionC",
      Info: "When passing a streetcar, ensure passengers are not getting on or off. If they are, stay at least 2 m behind the back doors. If a safety island exists, drive cautiously and be on the lookout for pedestrians.",
    },

    {
      Question: "Which one of these statements is a basic parking rule?",
      Image: "basicParkingRule",
      OptionA: "Park in between intersections.",
      OptionB: "Do not park within 3 m of a fire hydrant.",
      OptionC: "Do not park within 150 m of intersections with traffic lights.",
      OptionD: "Do not look while opening your door when you have parked your vehicle.",
      Correct: "OptionB",
      Info: "Park where you will not block other parked vehicles, sidewalks or roadways. Never park on a curve, near a crosswalk, fire hydrant or near an intersection. Be careful and watch for cyclists and pedestrians while opening your door.",
    },

    {
      Question: "High occupancy vehicle lanes on a provincial highways are reserved for vehicles carrying at least how many passengers?",
      Image: "highOccupancyLanes",
      OptionA: "Driver, plus 1 other passenger.",
      OptionB: "Driver, plus 2 other passengers.",
      OptionC: "Driver, plus 3 other passengers.",
      OptionD: "Driver, plus 4 other passengers.",
      Correct: "OptionA",
      Info: "Other roadways will specify the number required. Obey the number of passengers required or risk getting a fine and demerit points.",
    },

    {
      Question: "What should you do if you hit a deer/moose?",
      Image: "deerMouse",
      OptionA: "Try to move the animal off the road so other vehicles will not hit it.",
      OptionB: "Nothing, continue on.",
      OptionC: "Report the incident to the ministry of natural resources or local police.",
      OptionD: "Flee the scene.",
      Correct: "OptionC",
      Info: "Never try to move an injured animal as it may increase your chances of being hit by another vehicle.",
    },

    {
      Question: "What should you do when approaching a construction zone?",
      Image: "constructionZone",
      OptionA: "Slow down as signs may be posted with reduced speed limits.",
      OptionB: "Obey all warning signs.",
      OptionC: "Follow the directions of the traffic control worker.",
      OptionD: "All of the above.",
      Correct: "OptionD",
      Info: "Fines for speeding are doubled when workers are present in construction zones.",
    },

    {
      Question: "What should you do when you feel drowsy while driving?",
      Image: "drowsy",
      OptionA: "Nothing.",
      OptionB: "Speed up.",
      OptionC: "Take a nap off the road in a safe area.",
      OptionD: "Call your boss to tell him your arriving in 5 minutes.",
      Correct: "OptionC",
      Info: "Driving while drowsy is linked to many accidents, so ensure you are well-rested and alert.",
    },

    {
      Question: "Under what circumstances are cell phones not allowed while driving?",
      Image: "cellPhone",
      OptionA: "When you use your cell phone to text.",
      OptionB: "When you use your cell phone to chat.",
      OptionC: "When you use your cell phone to send emails.",
      OptionD: "All of the above.",
      Correct: "OptionD",
      Info: "Convicted drivers face fines and demerit points while a novice driver faces license suspension and cancellation.",
    },

    {
      Question: "What does the Accessible parking permit give you?",
      Image: "accessibleParkingPermit",
      OptionA: "Nothing.",
      OptionB: "Allows you to speed up.",
      OptionC: "Allows parking everywhere during specified times.",
      OptionD: "Allows parking in designated spaces for you or passengers with you who qualified for the parking permit.",
      Correct: "OptionD",
      Info: "The permit must belong to you or one of your passengers.",
    },

    {
      Question: "When approaching a stopped emergency vehicle with its sirens or red lights flashing or a tow truck with amber lights flashing, you must...?",
      Image: "stoppedEmergencyVehicleSirens",
      OptionA: "Slow down and drive cautiously. You must leave one lane clearance between you and an emergency vehicle if there are multiple lanes in your direction.",
      OptionB: "Nothing.",
      OptionC: "Slow down, stop and pull over to the right.",
      OptionD: "Slow down, stop and pull over to the left.",
      Correct: "OptionA",
      Info: "For a first offence you can be fined $400-$2000, get 3 demerit points and possible license suspension.",
    },

    {
      Question: "When dense fog forms while driving you should?",
      Image: "denseFog",
      OptionA: "Pass vehicles that are too slow normally.",
      OptionB: "Pull over and wait for the fog to clear.",
      OptionC: "Turn on your emergency lights and park in a safe spot.",
      OptionD: "Slow down, and turn on your fog lights.",
      Correct: "OptionD",
      Info: "Get off the road completely and wait for the fog to clear. Keep your emergency lights on.",
    },

    {
      Question: "If you skid on a slippery road you should?",
      Image: "skiddingOnRoad",
      OptionA: "Steer your vehicle into the direction you wish to travel.",
      OptionB: "Steel the vehicle into the opposite direction you want to go.",
      OptionC: "Increase speed to go faster.",
      OptionD: "Stomp on the brake as hard as you can.",
      Correct: "OptionA",
      Info: "Skids usually occur due to the vehicle travelling at a faster pace than the road and environmental conditions permit.",
    },

    {
      Question: "What is black ice",
      Image: "blackIce",
      OptionA: "When snow is mixed with mud and then freezes.",
      OptionB: "When newly paved roads are wet.",
      OptionC: "When asphalt has a thin layer of ice on it.",
      OptionD: "When ice is mixed with snow.",
      Correct: "OptionC",
      Info: "Black ice can form anywhere, especially on bridges which get extra cold air from below. This causes the temperature to drop more quickly on the bridge forming a thin layer of ice.",
    },

    {
      Question: "What should you do if you encounter dangerous whiteout conditions?",
      Image: "whiteOut",
      OptionA: "Pass vehicles that are moving too slowly or following too closely.",
      OptionB: "Pull onto the shoulder and wait for the conditions to change.",
      OptionC: "Turn on emergency flashers and pull off the road completely into a safe parking area.",
      OptionD: "Maintain speed and turn on your emergency flashers.",
      Correct: "OptionC",
      Info: "Dangerous whiteout conditions can mean serious accidents. Do not risk your life or endanger others. Find a safe parking area and wait to conditions to improve.",
    },

    {
      Question: "What vehicle has flashing blue lights that can be seen 150 m away?",
      Image: "blueLights",
      OptionA: "School bus.",
      OptionB: "Tow truck.",
      OptionC: "Snow removal vehicle.",
      OptionD: "Ambulance.",
      Correct: "OptionC",
      Info: "Emit slower speed around these vehicles.",
    },

    {
      Question: "If driving and your brakes fail, what should you do?",
      Image: "brakesFail",
      OptionA: "Nothing.",
      OptionB: "Keep your foot on the brake.",
      OptionC: "Pump the brakes to restore hydraulic brake pressure.",
      OptionD: "Sound horn and steer to a clear area.",
      Correct: "OptionC",
      Info: "Regular vehicle service with brake checking is the best way to avoid brake failure.",
    },

    {
      Question: "If you are in an accident with no personal injuries, and damages are $2,000 or less, you should...?",
      Image: "accidentless",
      OptionA: "Call a fire truck.",
      OptionB: "Move drivable vehicles off the road to enable traffic to move freely, and exchange information.",
      OptionC: "Nothing.",
      OptionD: "Call an ambulance.",
      Correct: "OptionB",
      Info: "A long as no one is hurt and damages are less than $2000, move drivable vehicles off the road safely to allow traffic to flow. THen call police services not 911, and obtain the closest collision reporting center to report the accident.",
    },

    {
      Question: "If you are in an accident with no personal injuries, but damages are over $2000, you should...?",
      Image: "accidentOver",
      OptionA: "Leave the scene, as 'no fault' insurance handles this.",
      OptionB: "Call police and give them information about the accident and damages.",
      OptionC: "Exchange information with the other party and leave.",
      OptionD: "All of the above.",
      Correct: "OptionB",
      Info: "Ensure you have witness contact information to give to the police.",
    },

    {
      Question: "When involved in or witness to an accident where someone has been injured, you should...?",
      Image: "involvedWithness",
      OptionA: "Call or have someone else call for help immediately.",
      OptionB: "Turn off the vehicle and turn on the emergency lights.",
      OptionC: "If you are not injured, stay calm and offer help until emergency services arrive.",
      OptionD: "All of the above.",
      Correct: "OptionD",
      Info: "Stay calm and cover accident victims with a blanket or jacket to reduce shock.",
    },

    {
      Question: "When and how often do you have to renew your driver's license?",
      Image: "renewDriverLicence",
      OptionA: "Whenever you receive a renewal application in the mail and/or before your license expires.",
      OptionB: "You don't need to, as you already have passed.",
      OptionC: "Every 5 years.",
      OptionD: "Every 10 years.",
      Correct: "OptionA",
      Info: "If you do not receive a renewal application or renewal postcard in the mail, you are still responsible for ensuring you have a valid driver's license that has not expired. Contact the ministry of transportation for further information.",
    },

    {
      Question: "On a vehicle's left side, what does a broken yellow pavement marking beside a solid yellow line mean?",
      Image: "brokenYellowPavementMarking",
      OptionA: "Cars travelling in the other direction may pas when the way is clear.",
      OptionB: "You may pass when the way is clear.",
      OptionC: "No cars may pass in either direction.",
      OptionD: "Both cars may pass in either direction.",
      Correct: "OptionB",
      Info: "Broken yellow lines in the lane to your left mean you may pass when the way is clear. There may even be a solid yellow line to the left of that, meaning you may still pass when clear but vehicles in the opposite direction may not.",
    },

    {
      Question: "If a traffic light is red, but there is a green arrow pointing left, what does that mean?",
      Image: "greenTrafficArrowPointingLeft",
      OptionA: "Vehicles in the left turning lane may turn left.",
      OptionB: "No vehicles may turn- only pedestrians may cross.",
      OptionC: "The direction of the green arrow indicates there is a one-way street.",
      OptionD: "All of the above.",
      Correct: "OptionA",
      Info: "If the arrow turns yellow, a green light will soon appear in one or more directions.",
    },

    {
      Question: "What does a flashing amber or yellow light mean?",
      Image: "yellowLight",
      OptionA: "Slow down and drive with caution.",
      OptionB: "Turn left.",
      OptionC: "Stop and turn left.",
      OptionD: "Stop and proceed with it is safe.",
      Correct: "OptionA",
      Info: "Flashing amber lights whether alone or within a traffic light warn you to slow down and proceed cautiously in the direction you are going.",
    },

    {
      Question: "What does a flashing red traffic light mean?",
      Image: "flashingRedTrafficLight",
      OptionA: "Slow down and drive with caution.",
      OptionB: "Stop and proceed when it is safe.",
      OptionC: "Nothing.",
      OptionD: "Stop, and go back where you came from, no entry in this area.",
      Correct: "OptionB",
      Info: "Always bring your vehicle to a full stop when stopping at a red flashing light.",
    },

    {
      Question: "Which statement is correct regarding red lights?",
      Image: "redLights",
      OptionA: "You may turn right on a red light, unless otherwise posted, provided you stop first.",
      OptionB: "Come to a complete stop and then proceed.",
      OptionC: "If you are in a hurry, you may drive through red lights with caution only if the way is clear.",
      OptionD: "All of the above.",
      Correct: "OptionA",
      Info: "You must always come to a complete stop at the appropriate road markings or intersection edge on a red light before making any turns.",
    },

    {
      Question: "What do advanced green lights tell you?",
      Image: "advancedGreenLights",
      OptionA: "You have the right-of-way to go in any direction in the intersection from the correct lane.",
      OptionB: "They allow pedestrians to go first.",
      OptionC: "A flashing green light means only vehicles turning left may do so.",
      OptionD: "None of the above.",
      Correct: "OptionA",
      Info: "When facing an advanced green light, oncoming traffic, faces a red light, enabling you to turn left, go straight or turn right from the appropriate lane.",
    },

    {
      Question: "What do white markings in this diagram represent?",
      Image: "whiteMarkings",
      OptionA: "Two parallel white lines at an intersection indicate a crosswalk for pedestrians.",
      OptionB: "They indicate there is a one-way street to the right.",
      OptionC: "These solid lines indicate there is no passing in either direction.",
      OptionD: "These do not indicate anything different from the directions.",
      Correct: "OptionA",
      Info: "Intersections may have a stop line, parallel lines or a sidewalk. If these are absent, stop at the intersection edge in order to allow pedestrians to cross; and remember, no passing within 30 m of a crosswalk.",
    },

    {
      Question: "What is the best way to check your blind spots?",
      Image: "blindSpots",
      OptionA: "Through your side mirrors.",
      OptionB: "Through your rear view mirrors.",
      OptionC: "Turning your head and doing shoulder check.",
      OptionD: "Asking your passengers to check for you.",
      Correct: "OptionC",
      Info: "Mirrors can help reduce the extent of blind spots but they will not get rid of them entirely; only shoulder checks will. Adjust all mirrors to minimize blind spots.",
    },

    {
      Question: "How long must new drivers hold a class G1 license before their G2 road test?",
      Image: "g1ToRoadTest",
      OptionA: "6 months.",
      OptionB: "1 year.",
      OptionC: "2 months.",
      OptionD: "Any time.",
      Correct: "OptionB",
      Info: "If you take a ministry approved beginner driver education course while you have your G1 license and pass it, you can apply for your G1 road test in 8 months.",
    },

    {
      Question: "What level must your blood alcohol level be if you hold a class G1 license?",
      Image: "bloodAlcoholLevelG1",
      OptionA: "Less than 1.0.",
      OptionB: "Less than 0.8.",
      OptionC: "Less than 0.08.",
      OptionD: "0.",
      Correct: "OptionD",
      Info: "If you are caught drinking and driving, you will get a license suspension. You will also face penalties that are becoming tougher.",
    },

    {
      Question: "How many passengers may you have in a vehicle if you have class G1 license?",
      Image: "passengerG1",
      OptionA: "None.",
      OptionB: "1.",
      OptionC: "As many as there are working seatbelts.",
      OptionD: "None of the above.",
      Correct: "OptionC",
      Info: "Included must be a class G driver, meeting all requirements, in the front passenger seat and must be the only other passenger in the front seat with you. All other passengers must have working seatbelts.",
    },

    {
      Question: "What are the requirements of the G class driver that may accompany a G1 Driver?",
      Image: "gClassAccompanyG1",
      OptionA: "The G class driver must be in the front passenger seat.",
      OptionB: "The G class driver must have at least 4 years of driving experience.",
      OptionC: "The G class driver must have less than a 0.05% blood alcohol level; 0% if under 21 years old.",
      OptionD: "All of the above.",
      Correct: "OptionD",
      Info: "Included in the G class driver's experience is any valid G2 level experience.",
    },

    {
      Question: "What are you not allowed to do when you have your G1 license?",
      Image: "notAllowedG1",
      OptionA: "Drive on roads with speeds greater than 80 km/h.",
      OptionB: "Drive on any 400-series highway with speeds greater than 80 km/h.",
      OptionC: "Drive on roads with speeds greater than 90 km/h.",
      OptionD: "You are not allowed to drive on roads with speeds greater than 80 km/h during 9 A.M to 5 P.M.",
      Correct: "OptionB",
      Info: "Consult the ministry of transportation if unsure which 400 series highways or other highways a G1 driver may drive on.",
    },

    {
      Question: "Which of the following is false about level 2 (G2) drivers?",
      Image: "notTrueG2",
      OptionA: "You may drive between midnight and 5 A.M.",
      OptionB: "You may have a blood alcohol level of .05 or less.",
      OptionC: "Depending on your age and if no G class driver is with you, there may be passenger restrictions between midnight and 5 A.M.",
      OptionD: "Each passenger must have a working seatbelt.",
      Correct: "OptionB",
      Info: "Since G2 lasts at least 12 months, more privileges exist with this class than with G1 drivers.",
    },

    {
      Question: "What changes must you tell the MoT(Ministry of Transportation) about?",
      Image: "changesMot",
      OptionA: "If your name has changed.",
      OptionB: "If your address has changed.",
      OptionC: "If you have a different vehicle such as a new car.",
      OptionD: "All of the above.",
      Correct: "OptionD",
      Info: "When you name, address, or any major physical change to your car occurs, you must tell the MoT within 6 days.",
    },

    {
      Question: "Which of these statements is NOT true?",
      Image: "notTrue",
      OptionA: "You cannot drive if you are 19.",
      OptionB: "You may not use an imitation license.",
      OptionC: "You may not have more than one Ontario license.",
      OptionD: "You may not lend your license to anyone or let anyone borrow it.",
      Correct: "OptionC",
      Info: "There are license laws that all licensed drivers must follow, or face a fine.",
    },

    {
      Question: "Which of these situations may result in a driving suspension after a court order?",
      Image: "courtOrder",
      OptionA: "If you have a medical condition that affects your driving ability.",
      OptionB: "If you do not have your vehicle properly insured.",
      OptionC: "If you driver over the speed limit by 50 km/h or greater.",
      OptionD: "All of the above.",
      Correct: "OptionD",
      Info: "You need to abide all safety measures affecting you, others and your vehicle.",
    },

    {
      Question: "If you refuse a police roadside blood alcohol screening test, what can happen?",
      Image: "refuseBloodAlcohol",
      OptionA: "Nothing, you have the right to remain silent and abstain.",
      OptionB: "You have 24 hours to show up for a test.",
      OptionC: "You will be allowed to leave immediately.",
      OptionD: "Your license will be suspended and you will face criminal charges.",
      Correct: "OptionD",
      Info: "You must participate in a screening test Do not avoid it by thinking you can get off the charges.",
    },

    {
      Question: "What can give you a suspension or impaired driving charges?",
      Image: "suspensionOrImpairedDrivingCharge",
      OptionA: "If you hold a G1 or G2 license and your blood alcohol level is over 0.",
      OptionB: "If you have a blood alcohol level over 0.5.",
      OptionC: "If you are behind the wheel of a turned-off vehicle, but your blood alcohol level is over .08.",
      OptionD: "All of the above.",
      Correct: "OptionD",
      Info: "Your age and blood alcohol content also impacts suspension time.",
    },

    {
      Question: "What does compulsory automobile insurance mean?",
      Image: "insurance",
      OptionA: "You must have a valid driver's license to drive on roads.",
      OptionB: "Any passenger must have insurance.",
      OptionC: "You must have insurance coverage to drive.",
      OptionD: "All passengers must have insurance.",
      Correct: "OptionC",
      Info: "The Compulsory Automobile Insurance Act requires all Ontario drivers to carry proof of insurance.",
    },

    {
      Question: "If you find your self at an intersection facing a red light and a police officer instructing traffic tells you to go straight what should you do?",
      Image: "trafficOfficer",
      OptionA: "Stop and ignore the officer.",
      OptionB: "Wave back.",
      OptionC: "Follow the officers directions.",
      OptionD: "Park the car and ask the officer what is happening.",
      Correct: "OptionC",
      Info: "Always follow the directions of a police officer that is directing traffic.",
    },

    {
      Question: "Which of the following will result in 2 demerit points",
      Image: "demeritPoint",
      OptionA: "Failure to stop for a police officer.",
      OptionB: "Reckless or careless driving.",
      OptionC: "Exceeding posted speed limits by more than 50 km/h.",
      OptionD: "Having passengers under 16 not wearing their seatbelts.",
      Correct: "OptionD",
      Info: "Many infractions exist where you may receive a fine and/or 2 demerit points. Infraction A will give you 7 demerit points, while B and C will give you 6. Demerit points on your record stay for 2 years from the offence date",
    },

    {
      Question: "What does this hand signal mean?",
      Image: "leftTurnHand",
      OptionA: "Driver in vehicle is turning left.",
      OptionB: "Driver in vehicle is turning right.",
      OptionC: "Driver in vehicle is slowing down or stopping.",
      OptionD: "Driver in vehicle is exiting a highway.",
      Correct: "OptionA",
      Info: "If your brake lights or turn signals do not work, ensure you use proper hand signals to warn other drivers of your intentions. Use both for clarity if in doubt.",
    },

    {
      Question: "What does this hand signal mean?",
      Image: "rightTurnHand",
      OptionA: "Driver in vehicle is turning left.",
      OptionB: "Driver in vehicle is turning right.",
      OptionC: "Driver in vehicle is slowing down or stopping.",
      OptionD: "Driver in vehicle is exiting a highway.",
      Correct: "OptionB",
      Info: "If your brake lights or turn signals do not work, ensure you use proper hand signals to warn other drivers of your intentions. Use both for clarity if in doubt.",
    },

    {
      Question: "What does this hand signal mean?",
      Image: "breakingHand",
      OptionA: "Driver in vehicle is turning left.",
      OptionB: "Driver in vehicle is turning right.",
      OptionC: "Driver in vehicle is slowing down or stopping.",
      OptionD: "Driver in vehicle is exiting a highway.",
      Correct: "OptionC",
      Info: "If your brake lights or turn signals do not work, ensure you use proper hand signals to warn other drivers of your intentions. Use both for clarity if in doubt.",
    },

      

  ];