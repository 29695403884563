import styles from "./styles/resultGraph.module.css";
import { PieChart } from "@mui/x-charts";

const ResultGraph = (props) => {
  const screenWidth = window.innerWidth;
  return (
    <div className={styles.body}>
      <div className={`boldText`}>
        {props.section}: {props.score}%
      </div>
      <div className={styles.graph}>
      <PieChart
        colors={["green", "red"]}
        series={[
          {
            data: [
              { id: 0, value: props.correct },
              { id: 1, value: props.wrong },
            ],
          },
        ]}
        width={screenWidth <= 450 ? 75 : 100}
            height={screenWidth <= 450 ? 75 : 100}
        margin={{ right: 0 }}
      />
      </div>
    </div>
  );
};

export default ResultGraph;
