import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";

import styles from "./styles/optionsMenu.module.css";
import advancedOptionsIcon from "../assets/advancedOptions.svg"

const OptionsMenu = (props) => {
  return (
    <section className={styles.optionsMenu}>
      {/**************************** RegularOpitonsSection ****************************/}
      <div className={styles.regularOptionsSection}>
        <Form
          className={`${styles.testTypeOptionGroup} boldText`}
        >
          Type:
          <Form.Check
            inline
            key="Quick Test"
            className={styles.checkbox}
            type="checkbox"
            label="Quick Test"
            checked={props.quickTest}
            onClick={props.HandleQuickTestFunction}
          />
          <Form.Check
            inline
            key="Custom Test"
            className={styles.checkbox}
            type="checkbox"
            label="Custom Test"
            checked={props.customTest}
            onClick={props.HandleCustomTestFunction}
          />
          <Form.Check
            inline
            key="Exam Simulation"
            className={styles.checkbox}
            type="checkbox"
            label="Exam Simulation"
            checked={props.examSimulation}
            onClick={props.HandleExamSimulationFunction}
          />
        </Form>

        {/**************************** TimerOption ****************************/}
        <div className={`${styles.timerOption} boldText`}>
          Timer:
          <Form>
            {props.examSimulation ? (
              <Form.Check
                inline
                key="Timed"
                className={styles.checkbox}
                type="checkbox"
                label="Timed"
                checked={props.timed}
                onClick={props.HandleTimedFunction}
                disabled
              />
            ) : (
              <Form.Check
                inline
                key="Timed"
                className={styles.checkbox}
                type="checkbox"
                label="Timed"
                checked={props.timed}
                onClick={props.HandleTimedFunction}
              />
            )}
          </Form>
        </div>
      </div>

      {/**************************** CustomOptions ****************************/}
      {props.customTest ? (
        <Accordion
          className={`${styles.advancedOptionsSection} boldText`}
          flush
        >
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <img
                className={styles.advancedOptionsIcon}
                alt=""
                src={advancedOptionsIcon}
              />
              Advanced Options
            </Accordion.Header>

            {/**************************** QuestionsAmount ****************************/}
            <Accordion.Body className={styles.questionAmountGroup}>
              Question Amount
              <Form>
                <Form.Label>
                  Traffic Signs:
                  {"\t" + props.numOfTrafficSignQuestions}
                </Form.Label>

                <Form.Range
                  defaultValue={props.numOfTrafficSignQuestions}
                  onChange={(e) =>
                    props.setNumOfTrafficSignQuestionsFunction(
                      props.numOfTrafficRuleQuestions + e.target.value > 0
                        ? e.target.value
                        : 1
                    )
                  }
                  max={58}
                  min={
                    props.numOfTrafficRuleQuestions +
                      props.numOfTrafficSignQuestions >
                    0
                      ? 0
                      : 1
                  }
                />
              </Form>
              <Form>
                <Form.Label>
                  Traffic Rules:
                  {"\t" + props.numOfTrafficRuleQuestions}
                </Form.Label>

                <Form.Range
                  defaultValue={props.numOfTrafficRuleQuestions}
                  onChange={(e) =>
                    props.setNumOfTrafficRuleQuestionsFunction(
                      e.target.value + props.numOfTrafficSignQuestions > 0
                        ? e.target.value
                        : 1
                    )
                  }
                  max={79}
                  min={
                    props.numOfTrafficRuleQuestions +
                      props.numOfTrafficSignQuestions >
                    0
                      ? 0
                      : 1
                  }
                />
              </Form>
              {/**************************** TimerValue ****************************/}
              {!props.timed ? (
                <div></div>
              ) : (
                <div className={styles.questionAmountGroup}>
                  Time Limit
                  <Form>
                    <Form.Label>
                      Timer:
                      {"\t" + props.timerValue}
                    </Form.Label>
                    <Form.Range
                      defaultValue={props.timerValue}
                      onChange={(e) =>
                        props.setTimerValueFunction(e.target.value)
                      }
                      max={60}
                      min={1}
                    />
                  </Form>
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ) : (
        <div></div>
      )}
    </section>
  );
};

export default OptionsMenu;
